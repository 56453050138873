import {ErrorHandler, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, withXsrfConfiguration} from '@angular/common/http';
import {ToastrModule} from 'ngx-toastr';
import {SessionWrapperComponent} from './session-wrapper/session-wrapper.component';
import {NotfoundComponent} from './notfound/notfound.component';
import {WINDOW_PROVIDERS} from '@paperlessio/sdk/util/helpers';
import {environment} from '@environment';
import * as Sentry from '@sentry/angular';
import {PartialStore} from '@management/partials/partial.store';
import {SessionModule} from './session/session/session.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {TranslocoRootModule} from '@shared/transloco-root/transloco-root.module';
import {OverlayModule} from '@angular/cdk/overlay';
import {BaseModalModule} from '@paperlessio/sdk/modal/base-modal';
import {SessionControllerService} from './session/session-controller.service';
import {SubmissionGizmoModule} from '@shared/submission-gizmo/submission-gizmo.module';
import {FormUnavailableModule} from '@shared/form-unavailable/form-unavailable.module';
import {BucketStore} from '@management/base/bucket/bucket.store';
import {ParticipationFlowStore} from '@shared/participants/participation-flow.store';
import {REX_DEBUG_MESSAGES} from '@shared/rex/rex-debug-toggle';
import {IconsModule} from '@paperlessio/sdk/icons';
import {ParticipantTokenInterceptor} from './session/participant-token.interceptor';
import {RouterStore} from '@paperlessio/sdk/util';
import {provideApiBasePath, provideIntercomAppId, provideWebsocketConfig} from '@paperlessio/sdk/util/tokens';
import {SubmissionConfigService} from './submission-config.service';

@NgModule({
  declarations: [
    AppComponent,
    NotfoundComponent,
    SessionWrapperComponent
  ],
  bootstrap: [AppComponent], imports: [BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    AppRoutingModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      tapToDismiss: true
    }),
    TranslocoRootModule.forRoot({
      allowEmpty: true,
      logMissingKey: false,
      useFallbackTranslation: true,
      setActiveLanguage: true
    }),
    SessionModule,
    FormsModule,
    OverlayModule,
    BaseModalModule.forRoot(),
    SubmissionGizmoModule.forRoot(),
    IconsModule.forRoot(),
    FormUnavailableModule], providers: [
    provideApiBasePath(environment.api.core),
    provideIntercomAppId(environment.intercom_app_id),
    provideWebsocketConfig(environment.websocket),
    WINDOW_PROVIDERS,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({showDialog: false})
    },
    {
      provide: PartialStore,
      useValue: null,
    },
    {
      provide: BucketStore,
      useValue: null,
    },
    {
      provide: ParticipationFlowStore,
      useValue: null,
    },
    SessionControllerService,
    {
      provide: REX_DEBUG_MESSAGES,
      useValue: false
    },
    RouterStore,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ParticipantTokenInterceptor,
      multi: true
    },
    SubmissionConfigService,
    provideHttpClient(withInterceptorsFromDi(), withXsrfConfiguration({
      cookieName: 'CSRF-TOKEN',
      headerName: 'X-CSRF-TOKEN',
    }))
  ]
})
export class AppModule {}
