import type {ProcessFlowNodeType} from './process-flow-node-type';
import {excluded} from '../../util/excluded';
import {Size} from '../../util/size';
import {Point} from '../../util/point';
import {ProcessFlowNodeOutlet} from './process-flow-node-outlets';

export abstract class ProcessFlowBaseNode {
  id: number;
  local_uuid: string;
  node_uuid: string;
  type: ProcessFlowNodeType;
  name: string;
  slug: string;
  disabled: boolean;
  executed_at: Date;
  outlets_used_at: Date;
  disabled_at: Date;
  updated_at: Date;
  created_at: Date;
  input: any;
  output: any;
  used_outlets: string[];
  submission_id: number;
  submittable_id: number;
  settings: ProcessFlowBaseNodeSettings;
  recalculated_settings: ProcessFlowBaseNodeSettings;
  editor_position: Point;

  @excluded supportsOutlets = false;
  @excluded supportedOutlets: (ProcessFlowNodeOutlet | string)[];

  @excluded process_flow_id: number;

  @excluded iconClickable: boolean;
  @excluded meta: any;
  @excluded dimension: Size;
  @excluded position: Point;
  @excluded transform: string;
  @excluded order: number;

  protected constructor(v: Partial<ProcessFlowBaseNode>) {
    this.id = v.id;
    this.local_uuid = v.local_uuid;
    this.node_uuid = v.node_uuid;
    this.type = v.type;
    this.name = v.name;
    this.slug = v.slug;
    this.disabled = !!v.disabled;
    this.executed_at = v.executed_at != null ? new Date(v.executed_at) : null;
    this.outlets_used_at = v.outlets_used_at != null ? new Date(v.outlets_used_at) : null;
    this.disabled_at = v.disabled_at != null ? new Date(v.disabled_at) : null;
    this.updated_at = v.updated_at != null ? new Date(v.updated_at) : null;
    this.created_at = v.created_at != null ? new Date(v.created_at) : null;
    this.input = v.input;
    this.output = v.output;
    this.used_outlets = v.used_outlets?.length > 0 ? v.used_outlets : [];
    this.submission_id = v.submission_id;
    this.submittable_id = v.submittable_id;
    this.editor_position = {x: v.editor_position?.x ?? 0, y: v.editor_position?.y ?? 0};
    // IMPORTANT: this.settings must be initialized in the child class constructor!
    // IMPORTANT: this.recalculated_settings must be initialized in the child class constructor!

    this.process_flow_id = v.process_flow_id;

    this.iconClickable = false;

    this.meta = v.meta ?? {};
    this.dimension = v.dimension ? new Size(v.dimension) : null;
    this.position = {
      x: v.position?.x ?? 0,
      y: v.position?.y ?? 0
    };
    this.transform = v.transform;
  }

  get graphId(): string {
    return `node-${this.id}`;
  }
}

export abstract class ProcessFlowBaseNodeSettings {
  protected constructor(v: Partial<ProcessFlowBaseNodeSettings>) {
    Object.assign(this, v);
  }
}
